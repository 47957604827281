
import { defineComponent } from 'vue';
import moment from 'moment';
import CategoryService from '@/services/CategoryService';
import PriceFormatter from '@/mixins/PriceFormatter';
import UiErrors from '@/components/form/UiErrors.vue';
import UiOverlay from '../form/UiOverlay.vue';
import UiTextField from '../form/UiTextField.vue';
import UiCombobox from '../form/UiCombobox.vue';
import UiButton from '../form/UiButton.vue';

export default defineComponent({
  name: 'Packages',
  mixins: [PriceFormatter],
  props: {
    modelValue: {
      type: Array,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modal: false,
    errors: {} as { [key: string]: any },
    inventoryPackage: {
      code: '',
      details: {
        barcode: '',
        add: '',
      },
      primary_categories: [],
    },
    primaryCategories: [],
    primarySearchInput: '',
  }),
  components: {
    UiOverlay,
    UiTextField,
    UiCombobox,
    UiButton,
    UiErrors,
  },
  computed: {
    filtredPrimary(): any {
      return this.primaryCategories.filter((el: any) => el.title.toUpperCase()
        .includes(this.primarySearchInput.toUpperCase()));
    },
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  async mounted() {
    const primaryCategories = await CategoryService.primary({ take: 50 });

    this.primaryCategories = primaryCategories
      .sort((a: any, b: any) => a.details.order - b.details.order);
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;

      this.inventoryPackage = {
        code: '',
        details: {
          barcode: '',
          add: '',
        },
        primary_categories: [],
      };
    },
    editPackage(model: any) {
      this.inventoryPackage = model;
      this.openModal();
    },
    // createPackage() {
    //   this.model.push(this.inventoryPackage);
    //   this.closeModal();
    // },
    updatePackage() {
      this.errors = {};

      if (this.inventoryPackage?.details?.barcode?.length > 10) {
        this.errors.barcode = ['Barcode may not be greater than 10 characters.'];
      }

      if (Object.keys(this.errors)?.length) return;

      const index = this.model.indexOf(
        (el: any) => el.code === this.inventoryPackage.code,
      );

      this.model[index] = this.inventoryPackage;

      this.closeModal();
    },
    removePrimaryCategory(id: string) {
      this.inventoryPackage.primary_categories = this.inventoryPackage.primary_categories
        .filter((el: any) => el.id !== id);
    },
    formatDate(date: string): string {
      const newDate = moment(date).format('DD[.]MM[.]YY');

      return newDate;
    },
    onHoverMessage(date: string): string {
      return `Udgået ${
        this.formatDate(date)
      }. Lægemidlet kan være helt udgået eller midlertidigt udgået pga. leveringssvigt.`;
    },
  },
});
