
import { defineComponent } from 'vue';
import store from '@/store';
import axios from 'axios';
import fileDownload from 'js-file-download';
import UiOverlay from '../form/UiOverlay.vue';
import UiScroll from '../form/UiScroll.vue';
import UiDropzone from '../form/UiDropzone.vue';
import UiButton from '../form/UiButton.vue';

export default defineComponent({
  name: 'FileManager',
  props: {
    modelValue: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isProcessing: false,
    errors: [] as Array<any>,
    modal: false,
    files: [] as Array<any>,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    fileUploadSize() {
      return Number(process.env.VUE_APP_FILE_UPLOAD_SIZE);
    },
    dropzoneOptions() {
      return {
        url: `${this.baseApiUrl}/api/media/file/upload`,
        headers: {
          Authorization: `Bearer ${store.getters.token}`,
        },
        acceptedFiles: '.pdf',
        maxFilesize: this.fileUploadSize,
        maxFiles: 10,
      };
    },
    model: {
      get(): any {
        return this.modelValue;
      },
      set(val: any) {
        this.$emit('update:modelValue', val);
      },
    },
  },
  components: {
    UiOverlay,
    UiScroll,
    UiDropzone,
    UiButton,
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
      this.errors = [];
      this.removeAllFiles();
      this.files = [];
    },
    removeFileExtension(input: string) {
      return input.substr(0, input.lastIndexOf('.')) || input;
    },
    processFiles() {
      this.isProcessing = true;
      (this.$refs['ui-dropzone'] as any).processFiles();
    },
    removeAllFiles() {
      (this.$refs['ui-dropzone'] as any).removeAllFiles();
      this.files = [];
    },
    dropzoneAddedfiles(files: any) {
      files.forEach((el: any) => {
        if (el.accepted) this.files.unshift(el);
      });
    },
    dropzoneSuccess(file: any, response: any) {
      this.isProcessing = false;

      this.model.push({
        path: response.id,
        title: response.title,
      });

      this.closeModal();
    },
    dropzoneError(file: any, message: any) {
      this.isProcessing = false;

      if (file.size > this.fileUploadSize * 1024 * 1024) {
        this.errors.unshift(
          {
            file: this.removeFileExtension(file.name),
            message: '( File is too large )',
          },
        );
      }

      if (file.type !== 'application/pdf') {
        this.errors.unshift(
          {
            file: this.removeFileExtension(file.name),
            message: '( Unsupported file format )',
          },
        );
      }
    },
    dropzoneRemoveFile(file: any) {
      this.$nextTick(() => {
        this.files = this.files.filter((el: any) => el !== file);
        (this.$refs['ui-dropzone'] as any).removeFile(file);
      });
    },
    removeFile(id: string) {
      this.$nextTick(() => {
        this.model = this.model.filter((el: any) => el.path !== id);
      });
    },
    previewFile(id: string) {
      window.open(`${this.baseApiUrl}/api/media/show/${id}`, '_blank');
    },
    downloadFile(id: string, title: string) {
      axios({
        url: `${this.baseApiUrl}/api/media/download/${id}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        fileDownload(response.data, title);
      });
    },
  },
});
