<template>
  <div class="page-actions">
    <div class="wrapper max-content">
      <img src="@/assets/images/vinordic_logo.png" alt="Logo" class="logo" />
      <div
        class="btn outlined"
        :class="{ disabled: !isLoaded || isProcessing }"
        @click="cancel"
      >
        Cancel
      </div>
      <div
        class="btn"
        :class="{ disabled: !isLoaded || isProcessing }"
        @click="updateDrug()"
      >
        Save
      </div>
    </div>
  </div>
  <ProductNotFound v-if="loadingError && loadingError.status === 404" />
  <div class="loader" v-if="!isLoaded && !loadingError.status">
    <pre-loader />
  </div>
  <div v-if="isLoaded && drug.id" class="max-content">
    <div class="product-actions">
      <div class="col-1">
        <h2>Betalende</h2>
        <div :class="{ disabled: isProcessing }">
          <UiSwitch v-model="drug.promoted" />
        </div>
      </div>
      <div class="col-2">
        <h2>Publiceret</h2>
        <div :class="{ disabled: isProcessing }">
          <UiSwitch v-model="drug.published" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="sidebar">
        <div class="input-row">
          <UiTextField
            v-model="drug.title"
            class="title"
            :label="'Titel:'"
            read-only
          />
          <UiErrors :error-messages="errors.title" />
        </div>
        <div class="input-row">
          <UiTextField
            v-model="drug.details.dosage_form.text"
            :label="'Form og sammensætning:'"
            read-only
          />
        </div>
        <div class="input-row">
          <UiTextField
            v-model="drug.details.strength.text"
            :label="'Styrke:'"
            read-only
          />
        </div>
        <div class="input-row">
          <h2>Billeder</h2>
          <MediaManager
            v-model="drug.details.images"
            :disabled="!drug.promoted || isProcessing"
          />
        </div>
        <div class="input-row">
          <UiCombobox
            v-model="drug.activeingredient_categories"
            v-model:search-input="activeingredientSearchInput"
            @input="(event) => (activeingredientSearchInput = event.target.value)"
            :items="activeingredientCategories"
            multiple
            placeholder="Vælg aktive substanser"
            :disabled="isProcessing"
          />
          <UiErrors :error-messages="errors.activeingredient_categories" />
          <div
            v-if="drug.activeingredient_categories && drug.activeingredient_categories.length"
            class="chips"
          >
            <template
              v-for="category in drug.activeingredient_categories"
              :key="`activeingredient-${category.id}`"
            >
              <div class="chip fill" :class="{ disabled: isProcessing }">
                <div class="chip-content" :title="category.title">
                  <div class="title">{{ category.title }}</div>
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    @click="removeCategory('activeingredient', category.id)"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="input-row" v-if="drugPackages.length">
          <div :class="{ disabled: isProcessing }">
            <Packages v-model="drugPackages" />
          </div>
        </div>
        <div class="input-row">
          <UiCombobox
            v-model="drug.primary_categories"
            v-model:search-input="primarySearchInput"
            @input="(event) => (primarySearchInput = event.target.value)"
            :items="filtredPrimaryCategories"
            multiple
            placeholder="Vælg dyrearter"
            :disabled="isProcessing"
          />
          <UiErrors :error-messages="errors.primary" />
          <div
            v-if="drug.primary_categories && drug.primary_categories.length"
            class="chips"
          >
            <template
              v-for="category in drug.primary_categories"
              :key="`primary-${category.id}`"
            >
              <div class="chip large" :class="{ disabled: isProcessing }">
                <div class="chip-content" :title="category.title">
                  <img
                    :src="primaryCategoryIcon(category.id)"
                    onerror="this.onerror=null; this.remove();"
                    class="icon"
                  />
                  <div class="title">{{ category.title }}</div>
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    @click="removeCategory('primary', category.id)"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="input-row">
          <UiTextarea
            v-model="drug.details.withdrawal_period"
            :label="'Tilbageholdelsestid:'"
            :disabled="isProcessing"
          />
        </div>
        <!-- <div class="input-row">
          <UiTextField
            v-model="drug.details.strength.text"
            :label="'Dosering:'"
            read-only
          />
        </div> -->
        <div class="input-row" v-if="packageDeliveryRegulationCodes.length">
          <h2>Udleveringsbestemmelse</h2>
          <div class="text">{{ packageDeliveryRegulationCodes }}</div>
        </div>
        <div class="input-row">
          <UiTextField
            v-model="drug.atc_category.code"
            :label="'ATC-kode:'"
            read-only
          />
        </div>
        <div class="input-row">
          <div class="hint">{{ drugUpdatedData.company_name }}</div>
          <UiCombobox
            v-model="drug.producer"
            v-model:search-input="producerSearchInput"
            @input="(event) => (producerSearchInput = event.target.value)"
            :items="producers"
            placeholder="Vælg firma"
            :disabled="isProcessing"
          />
          <UiErrors :error-messages="errors.producer" />
        </div>
        <div class="file input-row">
          <h2>SPC</h2>
          <FileManager
            v-model="drug.details.files"
            :disabled="!drug.promoted || isProcessing"
          />
        </div>
        <div class="input-row">
          <UiCombobox
            v-model="drug.tag_categories"
            v-model:search-input="tagSearchInput"
            @input="(event) => (tagSearchInput = event.target.value)"
            :items="tagCategories"
            multiple
            placeholder="Vælg administrationsveje"
            :disabled="isProcessing"
          />
          <div
            v-if="drug.tag_categories && drug.tag_categories.length"
            class="chips"
          >
            <template
              v-for="category in drug.tag_categories"
              :key="`tag-${category.id}`"
            >
              <div class="chip" :class="{ disabled: isProcessing }">
                <div class="chip-content" :title="category.title">
                  <div class="title">{{ category.title }}</div>
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    @click="removeCategory('tag', category.id)"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="input-row">
          <UiCombobox
            v-model="drug.usage_categories"
            v-model:search-input="usageSearchInput"
            @input="(event) => (usageSearchInput = event.target.value)"
            :items="usageCategories"
            multiple
            placeholder="Vælg anvendelsesområde"
            :disabled="isProcessing"
          />
          <div
            v-if="drug.usage_categories && drug.usage_categories.length"
            class="chips"
          >
            <template
              v-for="category in drug.usage_categories"
              :key="`usage-${category.id}`"
            >
              <div class="chip" :class="{ disabled: isProcessing }">
                <div class="chip-content" :title="category.title">
                  <div class="title">{{ category.title }}</div>
                  <font-awesome-icon
                    :icon="['fal', 'times']"
                    @click="removeCategory('usage', category.id)"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="drug-smpc">
          <ui-button
            v-if="drug.details.smpc_details.smpc_id"
            class="primary"
            @click="drug.details.smpc_details = {}"
            :disabled="!drug.promoted || isProcessing"
          >
            Indsæt andet produktresumé
          </ui-button>
          <ui-button
            v-if="drug.details.smpc_details.smpc_id"
            class="primary ml"
            @click="confirmationModal = true"
            :disabled="!drug.promoted || isProcessing"
          >
            Rediger produktresumé
          </ui-button>
        </div>
        <h2>Produktresumé</h2>
        <div
          v-if="drug.details.smpc_details.smpc_id"
          :class="{ disabled: !drug.promoted || isProcessing }"
        >
          <template v-for="(smpc, i) in smpcSections" :key="`smpc-${i}`">
            <ui-expansion-panel v-model="smpc.open">
              <template v-slot:header>{{ smpc.title }}</template>
              <template v-slot:content>
                <div v-html="smpc.value"></div>
              </template>
            </ui-expansion-panel>
          </template>
        </div>
        <div v-else>
          <UiCombobox
            v-model="drug.details.smpc_details"
            v-model:search-input="smpcSearchInput"
            @input="(event) => (smpcSearchInput = event.target.value)"
            :items="smpcs"
            item-text="spmc_name"
            item-value="smpc_id"
            placeholder="Tilføj produktresumé"
            :disabled="!drug.promoted || isProcessing"
          />
        </div>
      </div>
    </div>
  </div>
  <ui-overlay v-model="confirmationModal" class="warning">
    <div class="confirmation-card">
      <div class="card-title">WARNING!</div>
      <div class="card-description">Do you want to save changes before leaving?</div>
      <div class="card-actions">
        <ui-button
          class="primary"
          :disabled="isProcessing"
          @click="editSmpc"
          >Confirm</ui-button
        >
        <ui-button
          class="secondary"
          @click="confirmationModal = false"
          :disabled="isProcessing"
          >Cancel</ui-button
        >
      </div>
    </div>
  </ui-overlay>
</template>

<script>
import { defineComponent } from 'vue';
import ProductNotFound from '@/components/ui/ProductNotFound.vue';
import moment from 'moment';
import slugify from 'slugify';
import InventoryService from '@/services/InventoryService';
import CategoryService from '@/services/CategoryService';
import SmPCService from '@/services/SmPCService';
import MedicineService from '@/services/MedicineService';
import UiErrors from '@/components/form/UiErrors.vue';
import UiSwitch from '@/components/form/UiSwitch.vue';
import UiTextField from '@/components/form/UiTextField.vue';
import UiTextarea from '@/components/form/UiTextarea.vue';
import UiCombobox from '@/components/form/UiCombobox.vue';
import UiButton from '@/components/form/UiButton.vue';
import UiExpansionPanel from '@/components/form/UiExpansionPanel.vue';
import Packages from '@/components/modals/Packages.vue';
import MediaManager from '@/components/modals/MediaManager.vue';
import FileManager from '@/components/modals/FileManager.vue';
import UiOverlay from '@/components/form/UiOverlay.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';

export default defineComponent({
  name: 'ProductUpdate',
  components: {
    ProductNotFound,
    UiErrors,
    UiSwitch,
    UiTextField,
    UiTextarea,
    UiCombobox,
    UiButton,
    UiExpansionPanel,
    Packages,
    MediaManager,
    FileManager,
    UiOverlay,
    PreLoader,
  },
  data: () => ({
    loadingError: {},
    isLoaded: false,
    isProcessing: false,
    errors: {},
    categoryTypes: [],
    activeingredientCategories: [],
    primaryCategories: [],
    tagCategories: [],
    usageCategories: [],
    deliveryregulationCategories: [],
    producers: [],
    loadedSmpcs: [],
    searchedSmpcs: [],
    smpcTemplateVersion: '',
    smpcSections: [],
    searchTimeoutId: 0,
    activeingredientSearchInput: '',
    primarySearchInput: '',
    tagSearchInput: '',
    usageSearchInput: '',
    producerSearchInput: '',
    smpcSearchInput: '',
    drug: {},
    drugPublished: false,
    drugPackages: [],
    drugUpdatedData: {},
    confirmationModal: false,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    promotedSmpcKeys() {
      return (
        this.smpcTemplateVersion === 'v9.0'
          ? process.env.VUE_APP_PROMOTED_SMPC_V9_0_KEYS
          : process.env.VUE_APP_PROMOTED_SMPC_V8_2_KEYS
      ).split(', ');
    },
    drugId() {
      return this.$route.params.drugId;
    },
    filtredPrimaryCategories() {
      return this.primaryCategories.filter((el) => el.title.toUpperCase()
        .includes(this.primarySearchInput.toUpperCase()));
    },
    smpcs() {
      return this.smpcSearchInput ? this.searchedSmpcs : this.loadedSmpcs;
    },
    packageDeliveryRegulationCodes() {
      return [
        ...new Set(
          this.drugPackages?.map((el) => el.deliveryregulation_category?.code),
        ),
      ]?.join(', ');
    },
  },
  watch: {
    async producerSearchInput(val) {
      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params = {
          title: val,
          take: 10,
        };

        this.producers = await InventoryService.producerSearch(params);
      }, 300);
    },
    async activeingredientSearchInput(val) {
      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params = {
          title: val,
          category_types: ['activeingredient'],
          take: 10,
        };

        const response = await CategoryService.search(params);

        this.activeingredientCategories = response.data;
      }, 300);
    },
    async tagSearchInput(val) {
      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params = {
          title: val,
          category_types: ['tag'],
          take: 10,
        };

        const response = await CategoryService.search(params);

        this.tagCategories = response.data;
      }, 300);
    },
    async usageSearchInput(val) {
      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        const params = {
          title: val,
          category_types: ['usage'],
          take: 10,
        };

        const response = await CategoryService.search(params);

        this.usageCategories = response.data;
      }, 300);
    },
    async smpcSearchInput(val) {
      if (!val) return;

      window.clearTimeout(this.searchTimeoutId);

      this.searchTimeoutId = window.setTimeout(async () => {
        this.searchedSmpcs = await SmPCService.list({ smpc_name: val, status: 'APPROVED' });
      }, 300);
    },
    'drug.details.smpc_details': {
      async handler(val) {
        if (!val || !val.smpc_id) {
          this.smpcSections = [];
          return;
        }

        try {
          this.isProcessing = true;

          const response = await SmPCService.showSmpc(val.smpc_id);

          this.smpcTemplateVersion = response?.artifact?.template_version;

          const sections = response?.smpc_details?.smpc_sections || [];
          const promoted = [];

          this.promotedSmpcKeys.forEach((key) => {
            const section = sections.find((s) => s.key === key);

            if (section) {
              section.open = true;
              promoted.push(section);
            }
          });

          const other = sections.filter((s) => !this.promotedSmpcKeys.includes(s.key));

          this.smpcSections = [...promoted, ...other]
            .map((s) => ({
              title: s.title,
              key: s.key,
              value: s.value,
              open: s.open || false,
            }));

          this.isProcessing = false;
        } catch (error) {
          this.isProcessing = false;
          this.drug.details.smpc_details = {};
          this.smpcSections = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  async mounted() {
    try {
      const code = this.drugId.match(/^\d{11}/);
      const drugIdCode = code?.[0] || this.drugId;

      const [
        categoryTypes,
        activeingredientCategories,
        primaryCategories,
        deliveryregulationCategories,
        tagCategories,
        usageCategories,
        drug,
        drugUpdatedData,
        producers,
        loadedSmpcs,
      ] = await Promise.all([
        CategoryService.typeList(),
        CategoryService.search({ category_types: ['activeingredient'] }),
        CategoryService.search({ category_types: ['primary'], take: 50 }),
        CategoryService.search({ category_types: ['deliveryregulation'], take: 50 }),
        CategoryService.search({ category_types: ['tag'] }),
        CategoryService.search({ category_types: ['usage'] }),
        InventoryService.drug(drugIdCode, { with_unpublished_units: true }),
        MedicineService.getProduct(drugIdCode),
        InventoryService.producerList(),
        SmPCService.list({ status: 'APPROVED' }),
      ]);

      this.categoryTypes = categoryTypes;
      this.activeingredientCategories = activeingredientCategories.data;
      this.primaryCategories = primaryCategories.data
        .sort((a, b) => a.details.order - b.details.order);
      this.deliveryregulationCategories = deliveryregulationCategories.data;
      this.tagCategories = tagCategories.data;
      this.usageCategories = usageCategories.data;
      this.drugUpdatedData = drugUpdatedData;
      this.producers = producers;
      this.loadedSmpcs = loadedSmpcs;

      let atcCategory = {};

      const atcCode = this.drugUpdatedData.medicine_details.atc;

      if (atcCode) {
        atcCategory = await CategoryService.atcShow(atcCode);
      } else {
        const atc = drug.entity_categories.find((el) => el.category_type_code === 'atc');

        atcCategory = await CategoryService.atcShow(atc.category_id);
      }

      this.drug = {
        id: drug.id,
        code: drug.code,
        slug: drug.slug,
        title: this.drugUpdatedData.name,
        producer: this.producers.find((el) => el.id === drug.producer_id) || {},
        promoted: drug.promoted,
        published: drug.published,
        details: {
          dosage_form: {
            active: this.drugUpdatedData.medicine_details.dosage_form.active,
            code: this.drugUpdatedData.medicine_details.dosage_form.code,
            text: this.drugUpdatedData.medicine_details.dosage_form.text,
          },
          strength: this.drugUpdatedData.medicine_details.strength,
          routes: this.drugUpdatedData.medicine_details.routes,
          withdrawal_period: drug?.details?.withdrawal_period,
          images: drug.details.images,
          files: drug.details.files,
          smpc_details: drug.details?.smpc_details
            ? {
              smpc_id: drug?.details?.smpc_details?.source,
              spmc_name: drug?.details?.smpc_details?.title,
            }
            : {},
          de_registration_date: drug?.details?.de_registration_date,
          quarantine_date: drug?.details?.quarantine_date,
        },
        atc_category: atcCategory,
        activeingredient_categories: drug.entity_categories
          ?.filter((ec) => ec.category_type_code === 'activeingredient')
          ?.map((el) => ({
            id: el.category_id,
            title: el.category_title,
            code: el.category_code,
            category_type_id: el.category_type_id,
            category_type_code: el.category_type_code,
          })),
        primary_categories: drug.entity_categories
          ?.filter((ec) => ec.category_type_code === 'primary')
          ?.map((el) => ({
            id: el.category_id,
            title: el.category_title,
            code: el.category_code,
            category_type_id: el.category_type_id,
            category_type_code: el.category_type_code,
          })),
        tag_categories: drug.entity_categories
          ?.filter((ec) => ec.category_type_code === 'tag')
          ?.map((el) => ({
            id: el.category_id,
            title: el.category_title,
            code: el.category_code,
            category_type_id: el.category_type_id,
            category_type_code: el.category_type_code,
          })),
        usage_categories: drug.entity_categories
          ?.filter((ec) => ec.category_type_code === 'usage')
          ?.map((el) => ({
            id: el.category_id,
            title: el.category_title,
            code: el.category_code,
            category_type_id: el.category_type_id,
            category_type_code: el.category_type_code,
          })),
      };

      this.drugPublished = drug.published;

      drug.units.forEach((u) => {
        const drugPackage = this.drugUpdatedData.packages?.find(
          (p) => u.code === p.code,
        );

        if (!drugPackage) {
          const unit = u;

          unit.deliveryregulation_category = u.entity_categories
            ?.find((ec) => ec.category_type_code === 'deliveryregulation');
          unit.id = null;

          this.drugPackages = [
            ...this.drugPackages,
            this.transformInventoryPackageForEdit(unit),
          ];
        }
      });

      this.drugUpdatedData.packages.forEach((p) => {
        const drugUnit = drug.units?.find(
          (u) => p.code === u.code,
        );

        if (!drugUnit) {
          this.drugPackages = [
            ...this.drugPackages,
            this.transformMedicinePackageForEdit(p),
          ];
        } else {
          drugUnit.details = p.package_details;
          drugUnit.details.package_prices = {
            consumer_price: {
              amount: p.package_details.package_price.consumer_price,
            },
          };
          drugUnit.details.latest_price_change_date = p.package_details.latest_price_change_date;
          drugUnit.details.latest_marketing_date = p.package_details.latest_marketing_date;

          drugUnit.deliveryregulation_category = this.deliveryregulationCategories.find(
            (drc) => drc.code === p.package_details.delivery_regulation.code,
          );

          this.drugPackages = [
            ...this.drugPackages,
            this.transformInventoryPackageForEdit(drugUnit),
          ];
        }
      });

      this.isLoaded = true;
    } catch (error) {
      if (error?.response?.status === 404) {
        this.loadingError = error.response;
        return;
      }

      if (error?.response?.status === 422 || error?.response?.status === 409) {
        this.$toast.show(error.response.data?.error?.message, 3000);
        return;
      }

      this.$toast.show('Server error', 3000);
    }
  },
  methods: {
    primaryCategoryIcon(id) {
      const category = this.primaryCategories.find((el) => el.id === id);

      if (!category) return '';

      return `/assets/Icons/Animals/PNG/${category.details.icon}_red.png`;
    },
    formatDate(date) {
      if (!date) return '';

      const newDate = moment(date).format('YMMDD');

      if (!moment(newDate).isValid()) return '';

      return newDate;
    },
    removeCategory(type, id) {
      this.$nextTick(() => {
        this.drug[`${type}_categories`] = this.drug[`${type}_categories`]
          .filter((el) => el.id !== id);
      });
    },
    transformDrugForSave(model) {
      const atc = {
        id: model.atc_category.id,
        title: model.atc_category.title,
        code: model.atc_category.code,
        category_type_id: model.atc_category.category_type_id,
        category_type_code: 'atc',
      };

      slugify.extend({
        '+': '-',
        '-': '-',
        ',': '-',
        '.': '-',
        '>': '-',
        '<': '-',
        '&': '-',
        '/': '-',
      });

      return {
        code: model.code,
        slug: slugify(
          `${model.code} ${model.title} ${model.details.dosage_form.text || ''} ${model.details.strength.text || ''}`,
          {
            replacement: '-',
            lower: true,
            strict: true,
          },
        ),
        title: model.title,
        producer_id: model.producer.id,
        promoted: model.promoted,
        published: model.published,
        details: {
          dosage_form: model.details.dosage_form,
          strength: {
            number: model.details.strength.number?.toString(),
            text: model.details.strength.text,
          },
          routes: model.details.routes,
          withdrawal_period: model.details.withdrawal_period,
          images: model.details.images?.map((el) => ({
            path: el.path,
            description: el.description,
            link: el.link?.url
              ? {
                url: el.link.url,
                text: '',
              }
              : '',
            default: el.default,
          })),
          files: model.details.files,
          smpc_details: model.details.smpc_details.smpc_id
            ? {
              source: model.details.smpc_details.smpc_id,
              title: model.details.smpc_details.spmc_name,
            }
            : '',
          de_registration_date: model.details.de_registration_date,
          quarantine_date: model.details.quarantine_date,
        },
        entity_categories: [
          atc,
          ...model.activeingredient_categories,
          ...model.primary_categories,
          ...model.tag_categories,
          ...model.usage_categories,
        ].map((el) => ({
          category_id: el.id,
          category_title: el.title,
          category_code: el.code,
          category_type_id: el.category_type_id,
          category_type_code: this.categoryTypes?.find(
            (type) => type.id === el.category_type_id,
          ).code,
        })),
      };
    },
    transformMedicinePackageForEdit(model) {
      return {
        code: model.code,
        slug: model.slug,
        title: model.package_details.package_size.text,
        details: {
          package_size: {
            text: model.package_details.package_size.text,
            number: model.package_details.package_size.number.toString(),
            unit: {
              code: model.package_details.package_size.unit.code,
              text: model.package_details.package_size.unit.text,
            },
          },
          package_type: model.package_details.package_type,
          barcode: model.package_details.barcode,
          add: model.package_details.add,
          withdrawal: model.package_details.withdrawal,
          multipack_number: model.package_details.multipack_number,
          total_multipack_number: model.package_details.total_multipack_number,
          package_prices: {
            consumer_price: {
              amount: model.package_details.package_price.consumer_price,
            },
          },
          latest_price_change_date: model.package_details.latest_price_change_date,
          latest_marketing_date: model.package_details.latest_marketing_date,
          withdrawal_date: model.package_details.withdrawal_date,
        },
        deliveryregulation_category:
          this.deliveryregulationCategories?.find(
            (drc) => drc.code === model.package_details.delivery_regulation.code,
          ) || {},
        primary_categories: [],
        published: true,
      };
    },
    transformInventoryPackageForEdit(model) {
      return {
        id: model.id,
        item_id: model.item_id,
        code: model.code,
        title: model.details.package_size.text,
        details: {
          package_size: {
            text: model.details.package_size.text,
            number: model.details.package_size.number.toString(),
            unit: {
              code: model.details.package_size.unit.code,
              text: model.details.package_size.unit.text,
            },
          },
          package_type: model.details.package_type,
          barcode: model.details.barcode,
          add: model.details.add,
          withdrawal: model.details.withdrawal,
          multipack_number: model.details.multipack_number,
          total_multipack_number: model.details.total_multipack_number,
          package_prices: {
            consumer_price: {
              amount: model.details.package_prices.consumer_price.amount,
            },
          },
          latest_price_change_date: model.details?.latest_price_change_date,
          latest_marketing_date: model.details?.latest_marketing_date,
          withdrawal_date: model.details?.withdrawal_date,
        },
        deliveryregulation_category: model.deliveryregulation_category,
        primary_categories: model.entity_categories
          ?.filter((ec) => ec.category_type_code === 'primary')
          ?.map((ec) => this.primaryCategories.find((p) => p.id === ec.category_id)),
        published: model.published,
      };
    },
    async updateDrug(newRoute) {
      try {
        this.isProcessing = true;
        this.errors = {};

        if (!this.drug.title) {
          this.errors.title = ['Title required'];
        }

        if (!this.drug.activeingredient_categories.length) {
          this.errors.activeingredient_categories = ['Category active ingredient required'];
        }

        if (!this.drug.primary_categories.length) {
          this.errors.primary = ['Category primary required'];
        }

        if (!this.drug.producer?.id) {
          this.errors.producer = ['Producer required'];
        }

        if (Object.keys(this.errors).length) {
          this.isProcessing = false;
          this.$toast.show('Please enter correct data', 3000);
          return;
        }

        const drugSpecs = this.transformDrugForSave(this.drug);

        await InventoryService.drugUpdate(this.drug.id, drugSpecs);

        const drugPackages = [];

        this.drugPackages.forEach((el) => {
          const p = {
            code: el.code,
            details: el.details,
            entity_categories: [],
            id: el.id,
            item_id: el.item_id,
            published: el.published,
            title: el.title,
          };

          const packageCategories = [
            ...el.primary_categories,
          ];

          if (el.deliveryregulation_category.id) {
            packageCategories.push(el.deliveryregulation_category);
          }

          p.entity_categories = packageCategories?.map((ec) => ({
            category_id: ec.id,
            category_title: ec.title,
            category_code: ec.code,
            category_type_id: ec.category_type_id,
            category_type_code: this.categoryTypes?.find(
              (type) => type.id === ec.category_type_id,
            ).code,
          }));

          if (p.id) {
            drugPackages.push(InventoryService.packageUpdate(p.id, p));
          } else {
            p.item_id = this.drug.id;
            drugPackages.push(InventoryService.packageCreate(p));
          }
        });

        await Promise.all(drugPackages);

        await MedicineService.approveProduct(this.drug.code);

        if (newRoute) {
          this.$router.push(newRoute);
          return;
        }

        if (!this.drug.published) {
          this.$router.push({ name: 'ProductUnpublished' });
          return;
        }

        this.$router.push({
          name: 'Product',
          params: { drugId: drugSpecs.slug || this.drug.id },
        });
      } catch (error) {
        this.isProcessing = false;

        if (
          error?.response?.status === 422
          || error?.response?.status === 404
          || error?.response?.status === 409
        ) {
          this.$toast.show(error.response.data?.error?.message, 3000);
        } else {
          this.$toast.show('Server error', 3000);
        }
      }
    },
    async editSmpc() {
      this.confirmationModal = false;

      await this.updateDrug({
        name: 'EditSmpc',
        params: { id: this.drug.details.smpc_details.smpc_id },
      });
    },
    cancel() {
      if (!this.drugPublished) {
        this.$router.push({ name: 'ProductUnpublished' });
        return;
      }
      this.$router.push({
        name: 'Product',
        params: { drugId: this.drug.slug || this.drug.id },
      });
    },
    // async deleteDrug() {
    //   try {
    //     this.isProcessing = true;

    //     await InventoryService.drugDelete(this.drugId);

    //     this.$router.push({ name: 'Home' });
    //   } catch (error) {
    //     this.isProcessing = false;
    //   }
    // },
  },
});
</script>

<style lang="scss" scoped>
.loader {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ml {
  margin-left: 10px;
}

.page-actions {
  width: 100%;
  background: #EB3D47;
  z-index: 20;
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .wrapper {
    display: flex;
    align-items: center;
    height: 70px;

    .logo {
      margin-right: auto;
      width: 130px;
    }

    .btn {
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
      font-family: $font-primary;
      font-weight: 400;
      font-size: 16px;
      border-radius: 10px;
      width: 100%;
      max-width: 140px;
      line-height: 34px;
      height: 34px;
      cursor: pointer;
      transition: all 0.3s;
      background: #f8f8f8;
      border: 1px solid #f8f8f8;
      color: #eb3d47;
      margin: 0 0 0 14px;

      &.outlined {
        color: #fff;
        background: #eb3d47;
        border: 1px solid #fff;
      }

      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}

.product-actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 0 60px 0;
  padding: 100px 0 0 0;

  .col-1 {
    margin: 0 auto 0 0;
  }

  .col-2 h2 {
    text-align: right;
  }
}

h2 {
  margin: 0 0 20px 0;
  font-weight: 600;
  font-family: $font-primary;
  font-size: 16px;
  color: #595959;
}

.input-row {
  margin-bottom: 35px;

  .text {
    color: #595959;
  }

  .hint {
    margin: 0 0 7px 0;
    color: #9d9d9d;
    font-size: 12px;
  }
}

.container {
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 0 0 200px 0;

  .sidebar {
    width: 533px;

    .chips {
      padding: 14px 0 0 0;
    }

    .chip {
      display: inline-block;
      margin: 0 14px 7px 0;
      border-radius: 25px;
      border: 1px solid #eb3d47;

      .icon {
        max-width: 25px;
        max-height: 25px;
        margin-right: 10px;
      }

      .chip-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 30px;
        padding: 0 10px;

        .title {
          margin-right: 16px;
          font-size: 16px;
          font-weight: 600;
          color: #eb3d47;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          max-width: 420px;
        }

        .fa-xmark {
          cursor: pointer;
          color: #eb3d47;
        }
      }

      &.large {
        .chip-content {
          height: 50px;
          padding: 0 20px 0 16px;
        }
      }

      &.fill {
        background: #eb3d47;

        .chip-content {
          .title {
            color: #fff;
          }

          .fa-xmark {
            color: #fff;
          }
        }
      }
    }
  }

  .content {
    width: calc(100% - 533px);
    box-sizing: border-box;
    padding-left: 100px;

    .drug-smpc {
      margin: 0 0 60px 0;
      text-align: right;
    }

    h2 {
      margin-bottom: 25px;
    }
  }
}

.ui-overlay.warning {
  display: flex;
  align-items: center;
}

.confirmation-card {
  box-sizing: border-box;
  padding: 40px 70px;
  width: 100%;
  max-width: 400px;
  background: #fff;
  border-radius: 10px;
  overflow-y: auto;
  pointer-events: auto;
  font-family: $font-primary;
  font-size: 16px;

  .card-title {
    margin-bottom: 34px;
    font-size: 32px;
    font-weight: 900;
    text-align: center;
    color: #eb3d47;
  }

  .card-description {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #707070;
  }

  .card-actions {
    text-align: center;

    .ui-button.primary {
      margin-bottom: 20px;
    }
  }
}

.disabled {
  opacity: 0.3;
  user-select: none;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .product-actions {
    padding: 20px 0 0;
    margin: 0 0 30px 0;
  }

  .container {
    padding: 0 0 60px;
  }
}
</style>
